<template>
  <a-drawer width="40%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item :label="$t('明星.明星分类')" prop="starCategory" >
        <a-select :placeholder="$t('通用.输入.请选择')+$t('明星.明星分类')" v-model="form.starCategory" allowClear>
          <a-select-option v-for="(item, index) in this.categoryList" :value="item.id+''"
                           :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :label="$t('明星.明星名字')" prop="starName" >
        <a-input v-model="form.starName" :maxLength="16" :placeholder="$t('通用.输入.请输入')+$t('明星.明星名字')" />
      </a-form-model-item>
      <a-form-item v-if="form.localeList.length > 0" :label="this.$t('通用.文本.多语言配置')" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" class="margin-bottom-10">
        <a-table :data-source="form.localeList" :columns="localeColumns" rowKey="id" :pagination="false" bordered>
          <span slot="locale" slot-scope="text, record">
           <custom-dict-tag :options="customDict.LocaleEnum" :value="record.locale"/>
          </span>
          <div slot="name" slot-scope="text, record" >
            <a-input v-model="record.name" :maxLength="16" :placeholder="$t('通用.输入.请输入')+$t('明星.明星名字')" @blur.native.capture="validatorLocaleName"   />
          </div>
          <div slot="action" slot-scope="text, record">
            <a-button type="primary" @click="getTranslation(record)">
              {{$t('通用.按钮.翻译')}}
            </a-button>
          </div>
        </a-table>
        <span style="color: red;">{{ errorMessage }}</span>
      </a-form-item>
      <a-form-model-item :label="$t('明星.明星照片')" prop="picture" >
        <a-upload
          name="picture"
          listType="picture-card"
          :multiple="false"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverImgUpload">
          <img
            v-if="form.picture"
            :src="form.picture"
            alt="picture"
            style="height: 300px; width: 300px;"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">{{$t('通用.按钮.上传')}}</div>
          </div>
        </a-upload>
        <span>推荐尺寸 60 x 60</span>
      </a-form-model-item>
      <a-form-model-item :label="$t('明星.背景图')" prop="backgroundPicture" >
        <a-upload
          name="backgroundPicture"
          listType="picture-card"
          :multiple="false"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverBgUpload">
          <img
            v-if="form.backgroundPicture"
            :src="form.backgroundPicture"
            alt="backgroundPicture"
            style="height: 198px; width: 375px;"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">{{$t('通用.按钮.上传')}}</div>
          </div>
        </a-upload>
        <span>推荐尺寸 375 x 198</span>
      </a-form-model-item>
      <a-form-model-item :label="$t('明星.基础粉丝数')" prop="fansNum" >
        <a-input-number style="width: 100%"  v-model="form.fansNum" @change="limitFansNum" :placeholder="$t('通用.输入.请输入')+$t('明星.基础粉丝数')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('明星.网址')" prop="webUrl" >
        <a-input v-model="form.webUrl" :maxLength="100" :placeholder="$t('通用.输入.请输入')+$t('明星.网址')"/>
      </a-form-model-item>
      <a-form-model-item :label="$t('明星.状态')" prop="status" >
        <a-radio-group button-style="solid" v-model="form.status">
          <a-radio-button v-for="(item, index) in this.customDict.StatusEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item :label="$t('明星.明星介绍')" prop="starInfo" >
        <editor v-model="form.starInfo" :placeholder="$t('通用.输入.请输入')+$t('明星.明星介绍')"/>
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getStar, addStar, updateStar } from '@/api/star/star'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject, uploadOss} from "@/api/tool/oss";
import {listCategory} from "@/api/star/category";
import Editor from "@/components/Editor/Editor";
import {translation} from "@/api/tool/common";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor,
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      submitLoading: false,
      formTitle: '',
      categoryList: [],
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: null,
      errorMessage:undefined,
      localeColumns: [
        {
          title: this.$t('通用.文本.语言'),
          dataIndex: 'locale',
          key: 'locale',
          scopedSlots: { customRender: 'locale' }
        }, {
          title: this.$t('明星.明星名字'),
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          key: 'name'
        }, {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'action',
          key: 'action',
          slots: { title: 'addbtn', customRender: 'name' },
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 表单参数
      form: {
        id: null,
        localeList: [],
        starCategory: null,
        webUrl:null,
        status:1,
        starName: null,

        picture: null,

        backgroundPicture: null,

        fansNum: null,

        followNum: null,

        starInfo: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        starCategory: [
          { required: true, message: this.$t('明星.明星分类')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        starName: [
          { required: true, message: this.$t('明星.明星名字')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        picture: [
          { required: true, message: this.$t('明星.明星照片')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        backgroundPicture: [
          { required: true, message: this.$t('明星.明星背景图')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        status: [
          { required: true, message: this.$t('明星.状态')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        fansNum: [
          { required: true, message: this.$t('明星.基础粉丝数')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        followNum: [
          { required: true, message: this.$t('明星.关注数')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        localeList: [],
        starCategory: null,
        starName: null,
        webUrl:null,
        status:1,
        picture: null,
        backgroundPicture: null,
        fansNum: null,
        followNum: null,
        starInfo: null,
        createTime: null,
        remark: null,
      }

    },
    /*text 要翻译的字段*/
    getTranslation(record) {
      if (!this.form.starName) {
        return
      }
      let data = {"text": this.form.starName, "locale": record.locale};
      translation(data).then(response => {
        record.name = response.data
      })
    },
    validatorLocaleName() {
      let errorMessage = "";
      var localeList = this.form.localeList;
      let localeEnum = this.customDict.LocaleEnum;
      localeList.forEach(e=>{
        if (!e.name) {
          let localeName = localeEnum.find(locale=>locale.type===e.locale).name;
          errorMessage += localeName + this.$t('通用.文本.不能为空')+';';
        }
      })
      if (errorMessage) {
        this.errorMessage = errorMessage;
        return false;
      }
      this.errorMessage=''
      return true;
    },
    initLocale() {
      //获取全局配置的语言
      const sysLangList = globalThis.$openLangList;
      //初始化多语言
      let localeList = [];
      let i = 1;
      this.customDict.LocaleEnum.forEach(e => {
        if(sysLangList.includes(e.type)){
        localeList.push({"id": i, "locale": e.type, 'name': ''})
        i++;
      }
    })
      this.form.localeList = localeList;
    },

    limitFansNum(fansNum) {
      let val = fansNum;
      if(val==null||val==undefined||val==''){
        val=0
      }
      if (val > 2147483647) {
        val = 2147483647
      }
      this.form.fansNum = val
    },

    // oss 上传开始
    async handlePreview(file) {
      let suffix = file.name.substring(file.name.lastIndexOf('.'));
      if (suffix === '.mp4') {
        this.handleVideoPreview(file);
      }else {
        this.handleImagePreview(file);
      }
    },

    async handleVideoPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewVideoVisible = true
    },
    async handleImagePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewImageVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },

    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'star'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'picture', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    coverBgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'star'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'backgroundPicture', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    // oss 上传结束


    getCategoryList () {
      listCategory().then(response => {
        this.categoryList = response.data
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.getCategoryList()
      this.formType = 1
      this.open = true
      this.initLocale();
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.getCategoryList()
      this.formType = 2
      const id = row ? row.id : ids
      getStar({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        let localeValid = this.validatorLocaleName();
        if (!localeValid) {
          return false;
        }
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateStar(this.form).then(response => {
              this.$message.success(
                this.$t('通用.文本.修改成功'),
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addStar(this.form).then(response => {
              this.$message.success(
                  this.$t('通用.文本.新增成功'),
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
